<template>
  <router-view />
</template>
<style>
@import url("../public/css/style.css");
#app {
  text-align: center;
  height: 100%;
}
</style>
